interface LevelObject {
  level: number
  points: number
}

export const minPointsForLevel: LevelObject[] = [
  {
    level: 0,
    points: 0,
  },
  {
    level: 1,
    points: 10,
  },
  {
    level: 2,
    points: 420,
  },
  {
    level: 3,
    points: 1000,
  },
  {
    level: 4,
    points: 4200,
  },
  {
    level: 5,
    points: 10000,
  },
  {
    level: 6,
    points: 25000,
  },
  {
    level: 7,
    points: 50000,
  },
  {
    level: 8,
    points: 10000000000,
  }]

export function getLevelBasedOnPoints(experiencePoints: number) {
  for (const level of minPointsForLevel) {
    if (experiencePoints < level.points) {
      return { level: level.level - 1, xpNeeded: level.points }
    }
  }

  return { level: 0, xpNeeded: 0, minPointsForLevel }
}
