import {
  clusterApiUrl,
  Connection,
  PublicKey
} from '@solana/web3.js'

console.log('DATABASE_URL', process.env.DATABASE_URL)

export const MONET_USER_PUBKEY = new PublicKey('monw5YS9itMBzCZ871G3ZsZXqUoD3RBpJC4HsjQUX9d')
export const MONET_NON_DEX_FEE_WALLET_PUBKEY = new PublicKey('moDXL7CzX53ByKTvRK3eTU1oDw5aQaXaYjjfnRTEy6V')

export const configPerEnv = {
  dev: {
    host: 'http://localhost:3000',
    solanaEnv: 'testnet',
    apiHost: 'http://localhost:4000',
    rpcHost: false
      ? 'https://testnet.genesysgo.net/'
      : clusterApiUrl('testnet'),
    backendRPCHost: true ? clusterApiUrl('devnet') : clusterApiUrl('devnet'),
    altBackendRpcHost: true ? clusterApiUrl('devnet') : clusterApiUrl('devnet'),
    puffToken: '5NsF4C2cM6Sa7jgM4nHGepwriG5p11y1akPYki1cNayx', // devnet: 8crBEjMoyGmUA4jsus4oNbzmS9skroExSVzhroidjaA6
    //usdcToken: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    solToken: 'So11111111111111111111111111111111111111112',
    candyMachineId: 'SgE3PvVMVEJYMyS5YxmL64gu8wKcByseAqbKhobcSfp',
    meApi: 'https://api-mainnet.magiceden.dev/v2/',
  },
  production: {
    host: 'https://monet.community',
    solanaEnv: 'mainnet-beta',
    apiHost: 'https://sac-discord-bot-5ptq.onrender.com',
    rpcHost: 'https://ophelia-rhkpb6-fast-mainnet.helius-rpc.com',
    backendRPCHost: `https://mainnet.helius-rpc.com/?api-key=be791bd3-8b02-4040-bead-1936a5d6d0e4`,
    altBackendRpcHost:
      'https://mainnet.helius-rpc.com/?api-key=be791bd3-8b02-4040-bead-1936a5d6d0e4',
    puffToken: 'G9tt98aYSznRk7jWsfuz9FnTdokxS6Brohdo9hSmjTRB',
    //usdcToken: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    solToken: 'So11111111111111111111111111111111111111112',
    candyMachineId: '7RCBr3ZQ8yhY4jHpFFo3Kmh7MnaCPi1bFuUgXUB9WURf',
    meApi: 'https://api-mainnet.magiceden.dev/v2/'
  },
}

export const ENV =
  (process.env.NEXT_PUBLIC_ENV as 'production' | undefined) ?? 'dev'
const config = configPerEnv[ENV]

export default config

export const GA_TRACKING_ID = 'G-8BQZKXLYCK'

const rpcHost = config.rpcHost
const backendRPCHost = config.backendRPCHost

const wsEndpoint = 
ENV === 'production' && rpcHost.includes('p2pify.com/')
  ? 'wss://ws-nd-462-697-347.p2pify.com/35efee518814512db4d365aa2cb8c150'
  : undefined;

export const connection = new Connection(rpcHost, {
  commitment: 'confirmed',
  httpHeaders: {
    referer: 'https://www.monet.community',
  },
  wsEndpoint
})

export const backendConnection = new Connection(backendRPCHost, {
  commitment: 'confirmed',
  httpHeaders: {
    referer: 'https://www.monet.community',
  },
  wsEndpoint
})


export const altBackendConnection = new Connection(config.altBackendRpcHost, {
  commitment: 'confirmed',
  httpHeaders: {
    referer: 'https://sol-incinerator.com/',
  },
})

//export const usdcToken = new PublicKey(config.usdcToken)
export const puffToken = new PublicKey(config.puffToken)
export const solToken = new PublicKey(config.solToken)

export const jwtKey = process.env.JWT_SECRET!

export function getBaseUrl() {
  // reference for vercel.com
  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`
  }

  // // reference for render.com
  if (process.env.RENDER_INTERNAL_HOSTNAME) {
    return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`
  }

  const host = typeof window !== "undefined" ? window?.location?.hostname : 'localhost'
  const protocol = typeof window !== "undefined" ? window?.location?.protocol : 'http:'
  const port = typeof window !== "undefined" ? window?.location?.port : process.env.PORT ?? 3000

  // assume localhost
  return `${protocol}//${host}${port ? ':' + port : ''}`
}

export const tokenToShowFromStart = ['SOL', 'USDC', 'PUFF', 'BONK', 'mSOL', 'JitoSOL', 'PYTH', 'SAMO', 'GECKO', 'WIF', 'JCO', 'wBTC']
export const preselectedTokens = ['SOL', 'PUFF', 'JCO', 'BONK', 'mSOL', 'JitoSOL', 'PYTH', 'WIF', 'SAMO']

export function getRedirectBaseUrl() {
  if (process.env.BASE_REDIRECT_URL) {
    return process.env.BASE_REDIRECT_URL
  }

  return getBaseUrl()
}