import { Image, Skeleton, Tooltip } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { useUser } from '../../common/auth/authHooks'
import { getLevelBasedOnPoints } from '../utils/levelUtils'

export const PlayerLvl: FC<{ experiencePoints?: number }> = ({
  experiencePoints,
}) => {
  const { level, xpNeeded } = getLevelBasedOnPoints(experiencePoints ?? 0)

  if (level === undefined) {
    return <Skeleton />
  } else {
    return (
      <Tooltip label={`Level ${level}`}>
        <Image src={`/images/rewards/levelBadges/${level}.svg`} width={40} />
      </Tooltip>
    )
  }
}
