import { FC } from 'react'
import { Image } from '@chakra-ui/react'
import NextLink from 'next/link'
import { FaStar } from 'react-icons/fa'

export const FloatingButton: FC<{}> = () => {
  return (
    <NextLink passHref href='/rewardsCenter'>
      <div className='button' style={{ position: 'fixed' }}>
        <FaStar size={28} />
      </div>
    </NextLink>
  )
}
