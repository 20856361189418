import 'react-alice-carousel/lib/alice-carousel.css'
import { Box, Image, Input } from '@chakra-ui/react'
import React, { ComponentProps, FC, useState } from 'react'

interface Props {
  imageUrl?: string | null
  gradientstart: string
  gradientend: string
}

export const ProfilePicture: FC<ComponentProps<typeof Image> & Props> = (
  props: Props
) => {
  const { imageUrl, gradientstart, gradientend, ...componentProps } = props

  return (
    <Box position='relative'>
      {imageUrl && (
        <Image
          src={imageUrl ?? 'Unknown'}
          transition='all .2s ease-in-out'
          fallback={
            <Box
              m='0 auto'
              bg={`linear-gradient(180deg, ${gradientstart} 0%, ${gradientend} 100%)`}
              transition='all .2s ease-in-out'
              {...componentProps}
            ></Box>
          }
          {...componentProps}
        />
      )}

      {!imageUrl && (
        <Box
          m='0 auto'
          bg={`linear-gradient(180deg, ${gradientstart} 0%, ${gradientend} 100%)`}
          transition='all .2s ease-in-out'
          {...componentProps}
        ></Box>
      )}
    </Box>
  )
}
