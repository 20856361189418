import {
  Box,
  VStack,
  HStack,
  Text,
  Button,
  Image,
  useColorMode,
} from '@chakra-ui/react'
import { FC, ComponentProps } from 'react'
import { formatFloatForDisplay } from '../../../utils/utils'
import { useUser } from '../../common/auth/authHooks'
import { PlayerLvl } from './PlayerLvl'

export const LevelCard: FC<{
  experiencePoints?: number
  theme?: string
  minimized?: boolean
}> = ({ experiencePoints, theme, minimized }) => {
  const colorMode = useColorMode()
  const isDarkMode = colorMode.colorMode === 'dark'
  const isTransparent = theme === 'transparent'

  return (
    <HStack
      border={[(minimized ? 'none' : '1px solid'), '1px solid', '1px solid']}
      borderColor={[isDarkMode ? '#444' : '#eee', isDarkMode ? '#444 !important' : '#eee !important']}
      borderRadius='full'
      bg={[
        minimized
          ? 'transparent'
          : isDarkMode
          ? 'cardBlack'
          : isTransparent
          ? 'rgba(255, 255, 255, 0.2)'
          : 'white',
        isDarkMode
          ? 'cardBlack'
          : isTransparent
          ? 'rgba(255, 255, 255, 0.2)'
          : 'white',
      ]}
      paddingLeft={['1', '2']}
      paddingRight={['1', '2']}
      paddingTop='1'
      paddingBottom='1'
      gap={[minimized ? 0 : 1, 2]}
    >
      <Text
        display={[minimized ? 'none' : 'inline', 'inline']}
        color={isDarkMode || isTransparent ? 'white' : 'black'}
      >
        <Text as='span' fontWeight='600'>
          Level
        </Text>
      </Text>
      <Box marginInlineStart={'0 !important'} w={'25px'}>
        <PlayerLvl experiencePoints={experiencePoints ? experiencePoints : 0} />
      </Box>
    </HStack>
  )
}

export const RewardsHeaderDetails: FC<{
  theme?: string
  className: string
  minimized?: boolean
  level?: string
}> = ({ theme, className, minimized, level }) => {
  const { data } = useUser()
  const colorMode = useColorMode()
  const isDarkMode = colorMode.colorMode === 'dark'
  const isTransparent = theme === 'transparent'

  return (
    <HStack
      bg={isDarkMode || isTransparent ? 'transparent' : '#f2f2f2'}
      alignItems='center'
      justifyContent='center'
      p={['2px', '5px']}
      borderRadius='full'
      className={className}
    >
      <LevelCard
        minimized={minimized}
        experiencePoints={data?.experiencePoints}
      />
      <HStack
        display={[minimized ? 'none' : 'flex', 'flex', 'flex']}
        border='1px solid'
        borderColor={isDarkMode ? '#444' : '#eee'}
        borderRadius='full'
        bg={
          isDarkMode
            ? 'cardBlack'
            : isTransparent
            ? 'rgba(255, 255, 255, 0.2)'
            : 'white'
        }
        paddingLeft='2'
        paddingRight='2'
        paddingTop='1'
        paddingBottom='1'
      >
        <Image src='/images/rewards/GemsShine.svg' />
        <Text
          fontWeight='600'
          color={isDarkMode || isTransparent ? 'white' : 'black'}
        >
          {formatFloatForDisplay(data?.gems)}
        </Text>
      </HStack>
    </HStack>
  )
}
