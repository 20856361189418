import 'react-alice-carousel/lib/alice-carousel.css'
import {
  Button,
  HStack,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Image,
  Text,
  useDisclosure,
  VStack,
  Skeleton,
  Box,
  FormControl,
  FormLabel,
  Switch,
  Flex,
  useColorMode,
} from '@chakra-ui/react'
import React, { FC, useState } from 'react'
import { Logo } from '../../dApp/components/Logo'
import { Player as Lottie } from '@lottiefiles/react-lottie-player'
import { useStartAuthTx } from '../../common/auth/startAuthHook'
import { useWalletSignInModal } from '../../../components/wallet-ui/useWalletSignInModal'
import { IoDiamondOutline } from 'react-icons/io5'
import NextLink from 'next/link'
import { AiOutlineClose } from 'react-icons/ai'

export const RewardsModal: FC<{
  className?: string
  container?: string
  showPopup?: boolean
  handleClosePopup: () => void
  handleDoNotShowAgain: () => void
}> = ({
  className,
  container,
  showPopup,
  handleClosePopup,
  handleDoNotShowAgain,
}) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'

  return (
    <>
      <Modal isOpen={showPopup || false} onClose={handleClosePopup}>
        <ModalOverlay />
        <ModalContent
          background={isDarkMode ? '#1f2023' : '#fcfcfc'}
          maxWidth={['96%', '96%', '700px']}
          borderRadius={30}
        >
          <ModalHeader alignSelf='flex-start'>
            <Button onClick={handleClosePopup} p='0 !important'>
              <AiOutlineClose color={isDarkMode ? 'white' : 'black'} />
            </Button>
          </ModalHeader>
          <ModalBody textAlign='center'>
            <VStack
              width='100%'
              position='relative'
              h={'360px'}
              paddingTop={'30px'}
              paddingBottom={'30px'}
              backgroundImage={'url("/images/rewards/MFCBanner.png")'}
              borderRadius={60}
              backgroundRepeat='no-repeat'
              backgroundPosition='center'
            >
              <VStack
                display={'flex'}
                w={'full'}
                h={'100%'}
                justifyContent={'space-between'}
              >
                <Box
                  border={'#D9D9D9 solid 1px'}
                  zIndex={1}
                  padding={'10px 40px 10px 40px'}
                  borderRadius={100}
                  fontSize={14}
                >
                  <Text size={'14x'} color={'white'}>
                    Monet Fortune Cookie
                  </Text>
                </Box>

                <Text
                  zIndex={1}
                  fontSize={16}
                  color={'#BDBDBD'}
                  w='80%'
                  alignSelf='center'
                  textAlign='center'
                >
                 Earn Free entries to raffles with MFC from Space Missions
                </Text>
              </VStack>
            </VStack>
            <VStack gap='1.4rem' pt={'6'}>
              <VStack gap={'4'}>
                  <NextLink href='https://www.stonedapecrew.com/space-missions/planets/' target='_blank' rel='noopener noreferrer'>
                  <Button variant={isDarkMode ? 'primaryDark' : 'primary'}>
                    Link to Space Missions
                  </Button>
                  </NextLink>
                <Button width='full' onClick={handleDoNotShowAgain}>
                  Stop Showing this
                </Button>
              </VStack>
            </VStack>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  )
}
