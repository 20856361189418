import { TriangleDownIcon } from '@chakra-ui/icons'
import {
  Button,
  ButtonProps,
  Stack,
  Text,
  useColorMode,
  SkeletonCircle,
  HStack,
  Box,
  Link,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useWallet } from '@solana/wallet-adapter-react'
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { defaultButtonStyle, defaultDarkModeButtonStyle } from './common'
import { useWalletModal } from './useWalletModal'
import { WalletConnectButton } from './WalletConnectButton'
import { WalletIcon } from './WalletIcon'
import { WalletModalButton } from './WalletModalButton'
import { FiLogOut, FiPlusCircle, FiSettings, FiUser } from 'react-icons/fi'
import { BiWallet } from 'react-icons/bi'
import { useRouter } from 'next/router'
import { useRecoilState } from 'recoil'
import { solanaAuthAtom } from '../../modules/common/auth/authAtom'
import NextLink from 'next/link'
import { ProfilePicture } from '../../modules/dApp/components/ProfilePicture'
import { useUserByWalletInRouter } from '../../modules/techRaffles/hooks/user'
import { RewardsHeaderDetails } from '../../modules/rewards'
import ThemeSwitch from '../../modules/dApp/components/ThemeSwitch'
import { useUser } from '../../modules/common/auth/authHooks'

export const WalletMultiButton: FC<
  ButtonProps & { theme?: string; variant?: string, deactivateProfileForMobile?: boolean }
> = ({ variant, children, theme, deactivateProfileForMobile, ...props }) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  const { publicKey, wallet, disconnect } = useWallet()
  const { setVisible } = useWalletModal()
  const [solanaAuth, setSolanaAuth] = useRecoilState(solanaAuthAtom)
  const [copied, setCopied] = useState(false)
  const [active, setActive] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const router = useRouter()
  const isMobileView = useBreakpointValue({
    base: true,
    lg: false,
  })

  const base58 = useMemo(() => publicKey?.toBase58(), [publicKey])

  const content = useMemo(() => {
    if (children) return children
    if (!wallet || !base58) return null
    return base58.slice(0, 4) + '..' + base58.slice(-4)
  }, [children, wallet, base58])

  const copyAddress = useCallback(async () => {
    if (base58) {
      await navigator.clipboard.writeText(base58)
      setCopied(true)
      setTimeout(() => setCopied(false), 400)
    }
  }, [base58])

  const openDropdown = useCallback(() => {
    setActive(true)
  }, [])

  const closeDropdown = useCallback(() => {
    setActive(false)
  }, [])

  const shortAddress = base58?.slice(0, 4) + '..' + base58?.slice(-4)

  const openModal = useCallback(() => {
    setVisible(true)
    closeDropdown()
  }, [closeDropdown])

  const navigateToSite = (url: string) => {
    void router.push(url)
  }

  // const { data: user, refetch } = useUserByWallet()
  const { data: user } = useUser()

  const loading = useMemo(() => {
    return !user
  }, [user])

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const node = ref.current

      // Do nothing if clicking dropdown or its descendants
      if (!node || node.contains(event.target as Node)) return

      closeDropdown()
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, closeDropdown])

  const MenuButton: FC<{ title: string; path: string }> = ({ title, path }) => {
    return (
      <NextLink passHref href={path}>
        <Button
          w='full'
          border='1px'
          borderColor={isDarkMode ? '#494949' : '#E9E9E9'}
          variant={isDarkMode ? 'secondaryDark' : 'secondary'}
          borderRadius='5px !important'
          py='20px'
        >
          {title}
        </Button>
      </NextLink>
    )
  }

  if (!wallet) return <WalletModalButton>{children}</WalletModalButton>
  if (!base58)
    return <WalletConnectButton {...props}>{children}</WalletConnectButton>

  return (
    <div className='wallet-adapter-dropdown '>
      {!loading && !variant && !!user && !(isMobileView && deactivateProfileForMobile) ? (
        <HStack>
          <Link href='/rewardsCenter'>
            <RewardsHeaderDetails
              minimized={true}
              className='pointer'
              theme={theme}
            />
          </Link>
          <ProfilePicture
            onClick={openDropdown}
            className='pointer'
            imageUrl={user.profilePictureUrl}
            gradientstart={user.gradientStart}
            gradientend={user.gradientEnd}
            w='40px'
            h='40px'
            rounded='full'
          />
        </HStack>
      ) : (
        <Button
          py='20px'
          display={['none', 'inline-flex']}
          variant={isDarkMode ? 'primaryDark' : 'primary'}
          aria-expanded={active}
          style={{ pointerEvents: active ? 'none' : 'auto', ...props.style }}
          onClick={() => {
            disconnect()
            setSolanaAuth(undefined)
          }}
          // <WalletIcon wallet={wallet} width={20} height={20} />
          leftIcon={<FiUser />}
          css={isDarkMode ? defaultDarkModeButtonStyle : defaultButtonStyle}
          {...props}
        >
          {content}
        </Button>
      )}

      {active && user && (
        <Stack
          pos='absolute'
          top='100%'
          transform='translateY(10px)'
          zIndex='100'
          right='0'
          borderRadius='20px'
          py='0.75rem'
          px='.5rem'
          border='1px solid'
          bg={isDarkMode ? 'backgroundBlack' : '#fff'}
          borderColor={isDarkMode ? '#494949' : '#BDBDBD'}
          alignItems='flex-start'
          overflow='hidden'
          ref={ref}
          gap='1'
        >
          <Text
            display={['flex', 'flex', 'none']}
            px='1rem'
            fontWeight='500'
            pb='.75rem'
            borderBottom='1px solid'
            borderColor={isDarkMode ? '#494949' : '#BDBDBD'}
            w='100%'
          >
            <WalletIcon
              wallet={wallet}
              width={20}
              height={20}
              style={{ marginRight: '.5rem' }}
            />
            {content}
          </Text>

          {!loading && !!user && (
            <HStack justifyContent='center' alignItems='center' w='full'>
              <ProfilePicture
                imageUrl={user.profilePictureUrl}
                gradientstart={user.gradientStart}
                gradientend={user.gradientEnd}
                w='30px'
                h='30px'
                rounded='full'
              />
              <Text fontWeight='bold'>{shortAddress}</Text>
            </HStack>
          )}

          <MenuButton title='Profile' path={`/u/${base58}`} />
          <MenuButton title='Admin Area' path='/admin' />

          <Button
            w='full'
            border='1px'
            py='20px'
            borderColor={isDarkMode ? '#494949' : '#E9E9E9'}
            variant={isDarkMode ? 'secondaryDark' : 'secondary'}
            borderRadius='5px !important'
            rightIcon={<FiLogOut />}
            onClick={() => {
              disconnect()
              setSolanaAuth(undefined)
              closeDropdown()
            }}
          >
            Log Out
          </Button>
          <HStack w='full' p='2' px='4' justifyContent='space-between'>
            <Text fontWeight='bold'>Theme</Text>
            <ThemeSwitch />
          </HStack>
        </Stack>
      )}
    </div>
  )
}
